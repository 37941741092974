<div *ngIf="workPackage"
     class="work-package--single-view"
     [ngClass]="{'work-package--single-view_with-columns': showTwoColumnLayout()}"
     data-selector="wp-single-view">
  
  <div class="card">
    <div class="card-body">
      <div class="wp-new--subject-wrapper" *ngIf="isNewResource">
        <op-editable-attribute-field [resource]="workPackage"
                                      [wrapperClasses]="'-no-label'"
                                      [fieldName]="'subject'"></op-editable-attribute-field>
      </div>
    
      <div class="wp-info-wrapper">
        <wp-status-button *ngIf="!isNewResource"
                          [workPackage]="workPackage">
        </wp-status-button>
        <attribute-help-text [attribute]="'status'"
                              [attributeScope]="'WorkPackage'"
                              *ngIf="!isNewResource"></attribute-help-text>
    
        <div class="work-packages--info-row"
              *ngIf="!isNewResource">
          <span [textContent]="idLabel"></span>:
          <span [textContent]="text.infoRow.createdBy"></span>
          <!-- The space has to be in an extra span
          because otherwise the browser would add a second space after it -->
          <span>&nbsp;</span>
          <op-user-link class="user-link"
                        [user]="workPackage.author"></op-user-link>
          <span>.&nbsp;</span>
          <span [textContent]="text.infoRow.lastUpdatedOn"></span>
          <span>&nbsp;</span>
          <op-date-time [dateTimeValue]="workPackage.updatedAt"></op-date-time>
          <span>.</span>
        </div>
    
        <wp-custom-actions [workPackage]="workPackage" class="custom-actions"></wp-custom-actions>
      </div>
    
      <div class="attributes-group -project-context __overflowing_element_container __overflowing_project_context"
            *ngIf="projectContext && projectContext.field"
            data-overflowing-identifier=".__overflowing_project_context">
        <div>
          <p class="wp-project-context--warning" [textContent]="text.project.required"></p>
          <div class="attributes-key-value"
                [ngClass]="{'-span-all-columns': descriptor.spanAll }"
                *ngFor="let descriptor of projectContext.field; trackBy:trackByName">
            <div class="attributes-key-value--key">
              <wp-replacement-label [fieldName]="descriptor.name">
                {{ descriptor.label }}
                <span class="required"
                      *ngIf="descriptor.field.required && descriptor.field.writable">*</span>
              </wp-replacement-label>
              <attribute-help-text [attribute]="descriptor.name"
                                    [attributeScope]="'WorkPackage'"></attribute-help-text>
            </div>
            <div class="attributes-key-value--value-container">
              <op-editable-attribute-field [resource]="workPackage"
                                            [fieldName]="descriptor.name"></op-editable-attribute-field>
            </div>
          </div>
        </div>
      </div>

      <div
        class="attributes-group -project-context hide-when-print"
        *ngIf="showSwitchToProjectBanner()"
      >
        <div>
          <span [innerHTML]="switchToProjectText"></span>
        </div>
      </div>
    
      <ng-container *ngFor="let component of prependedAttributeGroupComponents()">
        <ndc-dynamic [ndcDynamicComponent]="component"
                    [ndcDynamicInputs]="{ workPackage: workPackage }">
        </ndc-dynamic>
      </ng-container>
    
      <div class="attributes-group description-group">
        <div class="single-attribute work-packages--details--description">
          <op-editable-attribute-field [fieldName]="'description'"
                                      [resource]="workPackage"
                                      [isDropTarget]="true"
                                      [wrapperClasses]="'-no-label'">
          </op-editable-attribute-field>
        </div>
      </div>
    </div>
  </div>

  <div class="widget-boxes -grid task-page">
    <div
      *ngFor="let group of groupedFields; trackBy:trackByName"
      [hidden]="shouldHideGroup(group)"
      [attr.data-group-name]="group.name"
      [ngClass]="'__overflowing_' + group.id"
      [attr.data-overflowing-identifier]="'.__overflowing_' + group.id"
      class="attributes-group __overflowing_element_container widget-box -thin bg-white rounded-border m-0"
      style="border: 1px solid #000;"
    >
      <ng-container opWorkPackageIsolatedQuerySpace *ngIf="group.isolated">
        <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)"
                    [ndcDynamicInputs]="{ workPackage: workPackage,
                                          group: group,
                                          query: group.query }">
        </ndc-dynamic>
      </ng-container>

      <ng-container *ngIf="!group.isolated">
        <div class="attributes-group--header">
          <div class="attributes-group--header-container">
            <h3 class="attributes-group--header-text color-orange"
                [textContent]="group.name"></h3>
          </div>
        </div>

        <ndc-dynamic [ndcDynamicComponent]="attributeGroupComponent(group)"
                    [ndcDynamicInjector]="injector"
                    [ndcDynamicInputs]="{ workPackage: workPackage, group: group }">
        </ndc-dynamic>
      </ng-container>
    </div>

    <div
      *ngIf="isNewResource"
      class="work-packages--storages widget-box -thin bg-white rounded-border m-0"
      style="border: 1px solid #000;"
    >
      <div>
        <div class="op-tab-content--header">
          <span class="op-tab-content--header-text color-orange" [textContent]="text.attachments.label"></span>
        </div>

        <ndc-dynamic [ndcDynamicComponent]="attachmentListComponent()"
                    [ndcDynamicInputs]="{ resource: workPackage }">
        </ndc-dynamic>

        <ndc-dynamic [ndcDynamicComponent]="attachmentUploadComponent()"
                    [ndcDynamicInputs]="{ resource: workPackage }"
                    *ngIf="workPackage.canAddAttachments">
        </ndc-dynamic>
      </div>

      <op-storage
        *ngFor="let storage of projectStorages | async"
        [resource]="workPackage"
        [projectStorage]="storage"
        [allowLinking]="true"
        [allowUploading]="true"
      ></op-storage>
    </div>
  </div>
</div>
