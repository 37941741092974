<button
  class="button rounded-border mr-2"
  [attr.id]="buttonId"
  [attr.accesskey]="accessKey"
  [attr.title]="title"
  [attr.aria-label]="label"
  [disabled]="disabled"
  (click)="performAction($event)"
  [ngClass]="{ '-active': isActive }"
  data-test-selector="wp-filter-button"
>
  <op-icon icon-classes="{{ iconClass }} button--icon"></op-icon>
  <span class="button--text">
    {{ buttonText }}
    <span class="badge -secondary" *ngIf="initialized" [textContent]="filterCount"></span>
  </span>
  <span class="spot-icon spot-icon_dropdown"></span>
</button>
