<div class="bg-white p-4 mb-4 rounded-border" style="border: 1px solid #000;">
  <div class="upsale-notification"
    [ngClass]="{ 'upsale-notification_hide-breadcrumb': hide_breadcrumb }">
    <h2 class="color-orange" [textContent]="feature_title"></h2>
      <p *ngIf="feature_description">{{feature_description}}</p>
      <p *ngIf="!feature_description" class="upsale--feature-reference">
        {{text.benefits_description}}
        <br>
        {{text.premium_features_text}}
        <br>
        {{text.professional_support_text}}
      </p>

      <p class="upsale--feature-reference"
      [textContent]="more_info"></p>

    <video width="400"
          *ngIf="feature_video"
          [autoplay]="true"
          [loop]="true"
          [muted]="true"
          playsinline
          class="widget-box--teaser-video">
      <source [src]="feature_video" type="video/mp4">
    </video>

    <img [src]="feature_image" class="widget-box--teaser-image" *ngIf="feature_image">
    <img
      [src]="image.enterprise_edition"
      class="widget-box--teaser-image_default"
      *ngIf="!feature_image && !feature_video"
    >

    <p [innerHTML]="text.enterprise_info_html(feature_title)"></p>
    <p>{{text.upgrade_info}}</p>

    <a
      [attr.href]="'#'"
      class="button custom-secondary-button rounded-border"
      target=”_blank”>
      {{ text.button_contact_us }}
    </a>

    <a
      [attr.href]="'#'"
      target="_blank"
      class="button -primary custom-secondary-button rounded-border">
      <span class="spot-icon spot-icon_enterprise-addons"></span>
      {{ text.upgrade }}
    </a>
    <free-trial-button></free-trial-button>
  </div>
</div>
