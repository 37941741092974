<spot-drop-modal
  [opened]="dropModalOpen"
  [allowRepositioning]="false"
  (closed)="close()"
  alignment="bottom-start"
  class="op-project-list-modal"
>
  <button
    id="projects-menu"
    accesskey="5"
    aria-haspopup="true"
    class="op-app-menu--item-action"
    type="button"
    slot="trigger"
    (click)="toggleDropModal()"
  >
    <span
      class="op-app-menu--item-title ellipsis"
      [textContent]="currentProjectName()"
    ></span>
    <i class="op-app-menu--item-dropdown-indicator button--dropdown-indicator"></i>
  </button>

  <ng-container slot="body">
    <div class="op-project-list-modal--header">
      <h1
        class="op-project-list-modal--title"
        [textContent]="text.project.plural"
      ></h1>
    </div>
    <div
      class="op-project-list-modal--body spot-container"
      data-searchable-list-parent="true"
      *ngIf="projects$ | async as projects"
    >
      <spot-text-field
        [placeholder]="text.search_placeholder"
        [(ngModel)]="searchableProjectListService.searchText"
        [ngModelOptions]="{standalone: true}"
        data-test-selector="op-header-project-select--search"
        data-modal-focus-catcher-container="true"
        (keydown)="searchableProjectListService.onKeydown($event, projects)"
        (inputFocus)="textFieldFocused = true"
        (inputBlur)="textFieldFocused = false"
      >
        <span
          slot="before"
          class="spot-icon spot-icon_search"
        ></span>
      </spot-text-field>

      <ng-container *ngIf="(loading$ | async) === false; else loadingTemplate">
        <ul
          *ngIf="projects.length > 0; else noResultsTemplate"
          op-header-project-select-list
          [ngClass]="{ 'spot-list_active': textFieldFocused }"
          [projects]="projects"
          [searchText]="searchableProjectListService.searchText"
          [root]="true"
          data-list-root="true"
          data-test-selector="op-header-project-select--list"
        ></ul>

        <ng-template #noResultsTemplate>
          <span class="op-project-list-modal--no-results">
            {{text.no_results}}
          </span>
        </ng-template>
      </ng-container>

      <div class="spot-action-bar">
        <div class="spot-action-bar--right">
          <a
            class="button spot-action-bar--action rounded-border"
            [href]="allProjectsPath()"
          >
            <span class="spot-icon spot-icon_unordered-list"></span>
            <span [textContent]="text.project.list"></span>
          </a>
          <a
            *ngIf="canCreateNewProjects$ | async"
            class="button -primary spot-action-bar--action custom-secondary-button rounded-border"
            [href]="newProjectPath()"
          >
            <span class="spot-icon spot-icon_add"></span>
            <span [textContent]="text.project.singular"></span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <op-loading-project-list class="op-project-list-modal--loading"></op-loading-project-list>
  </ng-template>
</spot-drop-modal>
