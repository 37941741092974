<op-view-select
  [projectId]="projectId"
  [menuItems]="menuItems"
  baseRoute="calendar.page.show"
  viewType="WorkPackagesCalendar"
  class="op-sidebar--body"
>
</op-view-select>


<!-- <div class="op-sidebar--footer">
  <button
    *ngIf="(canCreateCalendar$ | async)"
    class="button -primary -expand custom-secondary-button rounded-border m-0"
    [uiSref]="createButton.uiSref"
    [uiParams]="createButton.uiParams"
    [title]="text.create_new_calendar"
  >
    <span class="spot-icon spot-icon_add"></span>
    <span [textContent]="createButton.title"></span>
  </button>
</div> -->
