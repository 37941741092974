<op-view-select
  [projectId]="projectId"
  [menuItems]="menuItems"
  baseRoute="team_planner.page.show"
  viewType="TeamPlanner"
  class="op-sidebar--body"
>
</op-view-select>

<!-- <div class="op-sidebar--footer">
  <button
    *ngIf="(canAddTeamPlanner$ | async)"
    class="button -primary -expand"
    [uiSref]="createButton.uiSref"
    [uiParams]="createButton.uiParams"
    [title]="createButton.title"
    data-test-selector="team-planner--create-button"
  >
    <span class="spot-icon spot-icon_add"></span>
    <span [textContent]="createButton.text"></span>
  </button>
</div> -->
