<section class="collapsible-section"
         [ngClass]="{ '-expanded': expanded }">

  <!-- <button
      class="collapsible-section--toggle-link"
      (click)="toggle($event)"
  > -->

  <div class="collapsible-section--toggle-link">
    <span class="collapsible-section--legend"
          [textContent]="sectionTitle">
    </span>
  </div>

  <div class="collapsible-section--body toggle-slide-animation"
       #sectionBody
       [hidden]="!expanded">
    <!-- Wrapped by component -->
  </div>
</section>
