<div
  [attr.data-tooltip]="trialRequested ? text.confirmation_info(created, email) : ''"
  [ngClass]="{'tooltip--top': trialRequested}">
  <button
    type="button"
    [disabled]="trialRequested"
    class="button -primary custom-secondary-button rounded-border"
    (click)="openTrialModal()"
  >
    {{ text.button_trial }}
  </button>
</div>
